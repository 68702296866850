import React, { Component } from 'react';
import { getImportRemittanceReportData } from '../services/ReportService';
import ImportRemittanceReceipt from '../components/Remittance/ImportRemittanceReceipt';
import RemittanceTable from '../components/Remittance/RemittanceTable';

export default class ImportRemittancesReceipt extends Component {
  constructor(){
    super();
    this.language = 'en';
    this.tid = window.location.pathname.split('/')[2];
    this.isReprintData = false;
    var reprint = window.location.search; 
    if (reprint === ''){
      this.isReprintData = false;
    }else{
      this.isReprintData = true;
    }
    this.state = { reportData: null };
  }

  componentDidMount() {
    getImportRemittanceReportData(this.tid, this.language).then((res) => {
      this.setState({ reportData : res.data });
    });
  }

  splitIntoPages(data, itemsPerPage) {
    const pages = [];
    for (let i = 0; i < data.length; i += itemsPerPage) {
        const page = data.slice(i, i + itemsPerPage);
        while (page.length < itemsPerPage) {
            page.push({}); // Add null or any placeholder to pad the page
        }
        pages.push(page);
    }
    return pages;
  }

  render() {
    const ITEMS_PER_PAGE = 20;
    const remittancePages = this.state.reportData ? this.splitIntoPages(this.state.reportData.remittances, ITEMS_PER_PAGE) : [];
    const h3Style = {
      backgroundColor: '#dfdfdf',
      fontSize: '10px',
      lineHeight: 'normal',
      padding: '0 9px',
    }    

    if (!this.state.reportData) {
      return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <h1>Receipt Generating...</h1>
        </div>
      );
    }

    this.state.reportData.pageCount = remittancePages.length + 1;
    
    return (
        <div height={"100%"}>
          <ImportRemittanceReceipt result={this.state.reportData} isBankCopy={false} isReprint={this.isReprintData} hideSignature={false} />
          <ImportRemittanceReceipt result={this.state.reportData} isBankCopy={true} isReprint={this.isReprintData} hideSignature={true} />
          {remittancePages.map((page, pageIndex) => (
          <div key={pageIndex} className="page">
            <RemittanceTable 
              remittances={page}
              startIndex={pageIndex * ITEMS_PER_PAGE}
              h3Style={h3Style}
              hideSignature={false}
              totalPages={remittancePages.length}
              reportData={this.state.reportData}
            />
            <RemittanceTable 
              remittances={page}
              startIndex={pageIndex * ITEMS_PER_PAGE}
              h3Style={h3Style}
              hideSignature={false}
              totalPages={remittancePages.length}
              reportData={this.state.reportData}
            />
          </div>
        ))}
        </div>
    );
  }
}
