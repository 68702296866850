import React, { Component } from "react";
import "./Remittance.css";

export default class RemittanceTable extends Component {
  render() {
    const ITEMS_PER_PAGE = 20;
    const { remittances, startIndex, h3Style, hideSignature, totalPages, reportData } = this.props;
    const pageNumber = Math.ceil(startIndex / ITEMS_PER_PAGE) + 2;
    return (
      <div className="print-per-page">
        <div className="header-space-table"></div>
        <p>TRN No: <b>{reportData.transactionNumber}</b> Date: <b>{reportData.remittanceDateDisplay}</b></p>
        <table border="1" style={{ width: '100%' }} id="bulk-table-data">
          <thead>
            <tr style={h3Style}>
              <th>#</th>
              <th>Remittance</th>
              <th>Code</th>
              <th>Destination</th>
              <th>Amount</th>
              <th>Name AR / EN</th>
              <th>Mobile</th>
            </tr>
          </thead>
          <tbody>
            {remittances.map((item, index) => (
              <tr key={startIndex + index}>
                <td>{Object.keys(item).length ? startIndex + index + 1 : "\u00A0"}</td>
                <td>{item.remitNo}</td>
                <td>{item.remitCode}</td>
                <td>{item.countryCity}</td>
                <td>{item.sendCurrency} {item.sendAmount}</td>
                <td>{item.name}</td>
                <td>{item.mobile}</td>
              </tr>
            ))}
          </tbody>
        </table>
        {!hideSignature && (
          <table width="100%">
            <tbody>
              <tr>
                <td width="55%" style={{ verticalAlign: "middle", textAlign: "center" }}>
                  <span className="w-50">Customer Signature / توقيع الزبون</span>
                </td>
                <td width="1%" style={{ verticalAlign: "middle" }}></td>
                <td width="55%" style={{ verticalAlign: "middle",  textAlign: "center" }}>
                  <span className="w-50">Cashier Signature / أمين الصندوق</span>
                </td>
              </tr>
            </tbody>
          </table>
        )}
        <p style={{ textAlign: 'right' }}>
          Page {pageNumber} / {totalPages + 1}
        </p>
        <div className="footer-space-table"></div>
      </div>
    );
  }
}
